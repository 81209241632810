<script setup lang="ts">
import type {
  SearchInputV2Guest,
  SearchInputV2Calendar,
} from '#build/components'

const { large = false, nextFlow = false } = defineProps<{
  large?: boolean
  nextFlow?: boolean
}>()

const {
  data,
  goToSearch,
  pageReloadSearch,
  setSubmittedSearch,
  trackingGoTo,
  trackingInputClick,
} = useSearchInput()

const calendarRef = ref<InstanceType<typeof SearchInputV2Calendar> | null>(null)
const guestRef = ref<InstanceType<typeof SearchInputV2Guest> | null>(null)

const nextStep = () => {
  if (!nextFlow) return

  if (!data.value.startDate || !data.value.endDate) {
    calendarRef.value?.openCalendar()
  } else if (!data.value.guest) {
    guestRef.value?.openDropdown()
  }
}

const handleSubmitSearch = () => {
  if (pageReloadSearch.value) {
    setSubmittedSearch(true)
    trackingGoTo('destination')
  } else goToSearch()
}
</script>

<template>
  <div>
    <div
      :class="[
        'relative flex w-min items-center rounded border border-gray-200 bg-white',
        large ? 'h-[52px] py-2 pl-5 pr-2' : 'h-[46px] py-1 pl-4 pr-1',
      ]"
    >
      <SearchInputV2Location
        :large="large"
        @clicked="trackingInputClick('destination')"
        @selected="nextStep"
      />
      <div class="mx-4 h-1/2 border-r border-gray-300" />
      <SearchInputV2Calendar
        ref="calendarRef"
        :large="large"
        @clicked="trackingInputClick('calendar')"
        @selected="nextStep"
      />
      <div class="mx-4 h-1/2 border-r border-gray-300" />
      <SearchInputV2Guest
        ref="guestRef"
        :large="large"
        @clicked="trackingInputClick('guests')"
      />

      <BaseButton
        :class="[
          'rounded !px-3 !py-2 !text-md',
          { 'ml-6': !large },
          { 'ml-8': large },
        ]"
        color="secondary"
        type="button"
        @click="handleSubmitSearch"
      >
        <BaseIcon name="search" :size="1.3" class="desktop:hidden" />
        <span class="hidden desktop:inline">{{ $t('search.search') }}</span>
      </BaseButton>
    </div>
  </div>
</template>
