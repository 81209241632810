<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { createListFilters } from '~/helpers/search/createListFilters'

const guestOptions = createListFilters(52)

const { large = false } = defineProps<{
  large?: boolean
}>()
const emits = defineEmits<{ clicked: [] }>()

const { data, setData } = useSearchInput()

const target = ref<HTMLDivElement | null>(null)
const openGuestDropdown = ref(false)

onClickOutside(target, () => {
  openGuestDropdown.value = false
})

const openDropdown = () => {
  openGuestDropdown.value = true
}

const handleClick = () => {
  openDropdown()
  emits('clicked')
}

const selectedOption = (value: number) => {
  setData('guest', value)
  openGuestDropdown.value = false
}

defineExpose({
  openDropdown,
})
</script>

<template>
  <div ref="target" class="relative w-max">
    <div class="relative">
      <div
        :class="[
          'cursor-pointer',
          {
            'text-gray-500': !data.guest && !openGuestDropdown,
            'font-medium text-gray-700': openGuestDropdown,
            'text-gray-700': data.guest && !openGuestDropdown,
            'pr-6 lowercase': data.guest,
            'text-md': !large,
          },
        ]"
        @click="handleClick"
      >
        {{ $t('search.capacity', { count: data.guest }) }}
      </div>
      <button
        v-if="data.guest"
        class="absolute inset-y-0 right-0 pt-0.5"
        type="button"
        @click="setData('guest', 0)"
      >
        <BaseIcon color="text-gray-700" name="close" :size="1.1" />
      </button>
    </div>
    <div
      v-if="openGuestDropdown"
      :class="[
        'absolute z-modal h-[198px] w-[163px] overflow-y-auto rounded border border-gray-200 bg-white shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)]',
        { 'top-[50px]': large },
        { 'top-[44px]': !large },
      ]"
    >
      <ul class="mb-0">
        <li
          v-for="option in guestOptions"
          :key="option.value"
          class="cursor-pointer py-2 text-center text-md hover:bg-gray-200"
          @click="selectedOption(option.value)"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>
