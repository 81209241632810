<script setup lang="ts">
const { large = false } = defineProps<{
  large?: boolean
}>()
const emits = defineEmits<{ selected: []; clicked: [] }>()

const { t } = useI18n()
const {
  breadcrumbsDestinationsIds,
  data,
  meta,
  destinations,
  fetchAllDestinations,
  fetchDestinationsAndHouses,
  fetchTopDestination,
  goToHouse,
  houses,
  keepLastSearchQuery,
  resetDestinationsAndHouses,
  setBreadcrumbsDestinationsIds,
  setData,
  setMeta,
} = useSearchInput()

const openSearchDropdown = ref(false)
const disableAnimateClearInput = ref(false)

const inputSearch = computed({
  get() {
    return meta.value.searchQuery
  },
  set(value) {
    setMeta('searchQuery', value)
  },
})

const placeholder = computed(() => {
  if (data.value?.location?.name) return ''
  return openSearchDropdown.value
    ? t('search.placeholder')
    : t('search.destination')
})

const onInput = async () => {
  setBreadcrumbsDestinationsIds([])
  openSearchDropdown.value = true
  await fetchDestinationsAndHouses(inputSearch.value)
}

const closeDropdown = () => {
  openSearchDropdown.value = false
}

const clearActiveLocation = () => {
  disableAnimateClearInput.value = true
  setData('location', {})
  inputSearch.value = ''
  setBreadcrumbsDestinationsIds([])
}

const handleAfterClick = () => {
  openSearchDropdown.value = false
  keepLastSearchQuery()

  if (data.value.location?.type === 'house') {
    return goToHouse()
  }

  emits('selected')
}

onMounted(async () => {
  await Promise.all([fetchTopDestination(), fetchAllDestinations()])
})

watch(openSearchDropdown, (value) => {
  if (value) {
    disableAnimateClearInput.value = false
    emits('clicked')
  }
  if (destinations.value.length && !data.value.location.id) {
    const firstDestination = destinations.value[0]
    setData('location', {
      id: firstDestination.id,
      name: firstDestination.name,
      type: 'destination',
    })
  }
  inputSearch.value = ''
  resetDestinationsAndHouses()
})

const onEnter = (event: Event) => {
  if (destinations.value.length) {
    const firstDestination = destinations.value[0]
    setData('location', {
      id: firstDestination.id,
      name: firstDestination.name,
      type: 'destination',
    })
  } else if (houses.value.length) {
    const firstHouse = houses.value[0]
    setData('location', {
      id: firstHouse.id,
      name: firstHouse.name,
      slug: firstHouse.slug,
      type: 'house',
    })
  }

  const target = event.target as HTMLInputElement
  target.blur()

  handleAfterClick()
}

const handleSelected = () => {
  closeDropdown()
  emits('selected')
}
</script>

<template>
  <div v-click-outside="closeDropdown">
    <div class="relative flex items-center">
      <span
        v-if="data.location.name && !meta.searchQuery"
        :class="[
          'pointer-events-none inline-block max-w-[200px] truncate pr-6 text-left',
          { 'text-md leading-6': !large },
        ]"
      >
        {{ data.location.name }}
      </span>
      <input
        v-model="inputSearch"
        :class="[
          'max-w-full truncate border-gray-200',
          {
            'absolute left-0 top-0 bg-transparent pr-6':
              data.location.name && !meta.searchQuery,
          },
          {
            'transition-width duration-200 ease-in-out':
              !disableAnimateClearInput,
          },
          { 'placeholder:text-gray-500': !inputSearch },
          { 'w-[209px]': openSearchDropdown && !data.location.name },
          { 'w-[86px]': !openSearchDropdown && !data.location.name },
          { 'text-md': !large },
        ]"
        :placeholder="placeholder"
        autocomplete="off"
        type="text"
        @focus="openSearchDropdown = true"
        @input="onInput"
        @keyup.enter="onEnter"
      />

      <button
        v-if="inputSearch || data.location.name"
        class="absolute inset-y-0 right-0 pt-0.5"
        type="button"
        @click="clearActiveLocation"
      >
        <BaseIcon color="text-gray-700" name="close" :size="1.1" />
      </button>
    </div>

    <div
      v-if="openSearchDropdown"
      :class="[
        'absolute left-1/2 z-modal w-[666px] -translate-x-1/2 rounded border border-gray-200 bg-white p-6 text-left shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)]',
        large ? 'top-[60px]' : 'top-[55px]',
        {
          'laptop:w-[1024px]':
            !breadcrumbsDestinationsIds.length && !inputSearch.length,
        },
      ]"
    >
      <div
        v-if="!breadcrumbsDestinationsIds.length && !inputSearch.length"
        class="flex flex-col-reverse gap-4 laptop:flex-row laptop:gap-6"
      >
        <div class="laptop:w-[265px]">
          <SearchInputV2LocationOptions
            :search-query="inputSearch"
            @option-clicked="handleAfterClick"
          />
        </div>
        <div class="hidden w-px bg-gray-200 laptop:block" />
        <SearchInputV2LocationCountries
          @close="closeDropdown"
          @selected="handleSelected"
        />
      </div>
      <SearchInputV2LocationAll
        v-else-if="breadcrumbsDestinationsIds.length"
        @selected="handleSelected"
      />
      <div v-else>
        <SearchInputV2LocationOptions
          :search-query="inputSearch"
          with-image
          @option-clicked="handleAfterClick"
        />
      </div>
    </div>
  </div>
</template>
